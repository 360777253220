@import "./imports";

html {
  height: 100%;
  --osu-scarlet: #bb0000;
  --osu-scarlet-20: #960000;
  --osu-gray: #666666;
  --osu-gray-20: #e0e0e0;
  --osu-gray-70: #1e1e1e;
  --root-text-color: black;
  --root-background-color: white;
  color: var(--root-text-color);
  background-color: var(--root-background-color);
}
.h-accessible-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.d-flex {
  display: flex !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-column {
  flex-direction: column !important;
}
.align-items-center {
  align-items: center !important;
}
.align-self-center {
  align-self: center !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.spaceBetween {
  justify-content: space-between !important;
}
.d-none {
  display: none !important;
}
.m-0-auto {
  margin: 0 auto !important;
}
dt {
  font-size: 1.2rem !important;
}
dd, label {
  font-size: 1rem !important;
}
p {
  font-size: 1rem !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}
figcaption {
  font-size: 0.75rem !important;
  letter-spacing: 0.4px !important;
  font-weight: 400 !important;
}
.table-row-hover:hover {
  cursor: pointer !important;
}
.overflow-wrap-break-word {
  overflow-wrap: break-word !important;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}