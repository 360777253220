#notificationHistoryTable tr {
    border-bottom: 1px solid rgba(85, 85, 85, 0.125);
    height: 3rem;
}

#notificationHistoryTable tr:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .04);
}

#notificationHistoryTable th {
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    font-weight: 600;
}

#notificationHistoryTable td {
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.expanded-content-row {
    background-color: rgba(0, 0, 0, 0.02);
}

.expanded-content-row:hover {
    cursor: default !important;
    background-color: initial !important;
}

.notification-status-tooltip {
    background-color: var(--blue);
    max-width: 100%;
    text-align: left;
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px), (min-device-width: 768px) and (max-device-width: 1024px) {
    #notificationHistoryTable table, thead, tbody, th, td, tr { /* force table to not be a table anymore */
        display: block;
    }

    #notificationHistoryTable thead tr { /* hide table headers (without using display, to support accessibility) */
        left: -9999px;
        position: absolute;
        top: -9999px;
    }

    #notificationHistoryTable tr:first-of-type { /* first row has extra padding already */
        padding-top: 0;
    }

    #notificationHistoryTable tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        padding-top: 1rem;
        height: auto;
    }
    
    #notificationHistoryTable td { /* behave like a row */
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        min-height: 2rem;
        padding-left: 9rem;
        position: relative;
    }

    #notificationHistoryTable td:before { /* behave like a table header */
        content: attr(data-header);
        font-weight: bold;
        left: 0rem;
        position: absolute;
        white-space: nowrap;
    }

    .expanded-content-row > td {
        padding-left: 0rem !important;
        word-break: break-all;
    }
}