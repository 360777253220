a#skipToMain:active, a#skipToMain:focus { 
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    text-align: center;
    padding: 0.5em 0 1.5em 0;
    display: block;
    color: #fff;
    z-index: 999999999999999999;
    text-decoration: none;
    background: #666;
    background: rgba(0, 0, 0, 0.8);
    border: 1px dotted #ccc;
    border-top: none;
    border-radius: 0 0 6px 6px
}

a#skipToMain:active:hover, a#skipToMain:focus:hover { 
    background: #b00;
    background: rgba(187, 0, 0, 0.8);
}

.container {
    height: calc(100vh - 6rem);
}

.container.mobile {
    padding-bottom: 56px;
}

#main:focus {
    outline: none;
}

h1 {
    font-size: 1.75rem; /* size as h3 */
}

h2 {
    font-size: 1.5rem; /* size as h4 */
}

h3 {
    font-size: 1.25rem; /* size as h5 */
}

h4 {
    font-size: 1rem; /* size as h6 */
}

.required:after {
    color: red;
    content: " *";
    display: inline;
}