.thumbnailImage {
    display: block;
    height: 12.5rem;
    object-fit: scale-down;
}

@media(max-width: 374px) {
    .thumbnailImage {
        max-width: 17rem;
    }
}