.checklist-check {
    color: rgb(76, 217, 100)
}

.checklist-heading {
    font-size: 16px;
    font-weight: 400;
    color: black
}

.checklist-body {
    font-size: 13px;
    font-weight: lighter;
    color: #757575;
}